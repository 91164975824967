import classNames from 'classnames';
import type { ComponentProps } from 'react';

export type ModalTitleProps = ComponentProps<'h1'>;

export const ModalTitle = ({ className, ...restOfProps }: ModalTitleProps) => {
  return (
    <h1
      className={classNames('line-clamp-2 break-words text-24 font-semibold text-grey-12', className)}
      data-testid="MODAL_TITLE"
      {...restOfProps}
    />
  );
};
