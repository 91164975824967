import { Label } from '@air/primitive-label';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';
import { useField } from 'formik';
import type { ComponentProps, ReactNode } from 'react';

export type FormikFieldProps = ComponentProps<'div'> & {
  component: ReactNode;
  id: string;
  isLabelHidden?: boolean;
  isErrorHidden?: boolean;
  label: string;
  name: string;
  description?: string;
};

export const FormikField = ({
  className,
  component,
  id,
  isLabelHidden,
  isErrorHidden,
  label,
  name,
  description,
  ...restOfProps
}: FormikFieldProps) => {
  const formikId = `formik-field-${id}`;
  const [field, meta] = useField({ name });

  const hasError = !!meta.error && meta.touched;

  const componentProps = { hasError, ...field };

  return (
    <div className={classNames('relative flex flex-col gap-1', className)} {...restOfProps}>
      {!!label && isLabelHidden ? (
        <VisuallyHidden>
          <Label htmlFor={formikId} size="small">
            {label}
          </Label>
        </VisuallyHidden>
      ) : (
        <Label htmlFor={formikId} size="small">
          {label}
        </Label>
      )}

      <Slot id={formikId} {...componentProps}>
        {component}
      </Slot>

      {!hasError && description && (
        <p className="absolute top-full w-full translate-y-0.5 text-12 text-grey-10">{description}</p>
      )}
      {hasError && !isErrorHidden && (
        <div
          data-testid="FORM_ERROR"
          data-fieldid={id}
          className="form-field-error absolute top-full w-full translate-y-0.5 text-12 text-red-9"
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};
