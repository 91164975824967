import { Workspace } from '@air/api/types';
import { noop } from 'lodash';
import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { InternalSetWorkspaceModal, SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY } from './SysadminSetWorkspaceModal';
import { isAdminDotAirDotInc } from './utils/isAdminDotAirDotInc';

export type SysadminContextValue = {
  handleClearWorkspaceId: () => void;
  handleSetWorkspaceId: (workspaceId: Workspace['id']) => void;
  isInternal: boolean;
  workspaceId: Workspace['id'] | undefined;
};

const SYSADMIN_CONTEXT_DEFAULT_VALUE: SysadminContextValue = {
  handleClearWorkspaceId: noop,
  handleSetWorkspaceId: noop,
  isInternal: false,
  workspaceId: undefined,
};

export const SysadminContext = createContext<SysadminContextValue>(SYSADMIN_CONTEXT_DEFAULT_VALUE);

export type SysadminProviderProps = {
  children: ReactNode;
};

export const SysadminProvider = ({ children }: SysadminProviderProps) => {
  const [isInternal, setIsInternal] = useState(false);
  const [workspaceId, setWorkspaceId] = useState<Workspace['id'] | undefined>(undefined);

  useEffect(() => {
    if (isAdminDotAirDotInc()) {
      const recentlyUsed = localStorage.getItem(SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY);
      const recentlyUsedArray: Pick<Workspace, 'id' | 'name'>[] = recentlyUsed ? JSON.parse(recentlyUsed) : [];

      if (recentlyUsedArray.length) {
        setWorkspaceId(recentlyUsedArray[0].id);
      }

      setIsInternal(true);
    }
  }, []);
  /**
   * Actions for setting the workspace id.
   */
  const handleClearWorkspaceId = useCallback(() => setWorkspaceId(undefined), []);
  const handleSetWorkspaceId = useCallback((workspaceId: Workspace['id']) => {
    setWorkspaceId(workspaceId);
  }, []);

  const values = useMemo(
    () => ({
      handleClearWorkspaceId,
      handleSetWorkspaceId,
      isInternal,
      workspaceId,
    }),
    [handleClearWorkspaceId, handleSetWorkspaceId, isInternal, workspaceId],
  );

  return (
    <SysadminContext.Provider value={values}>
      {children}
      {isInternal && !workspaceId && <InternalSetWorkspaceModal />}
    </SysadminContext.Provider>
  );
};

export function useSysadmin() {
  const context = useContext(SysadminContext);

  if (context === SYSADMIN_CONTEXT_DEFAULT_VALUE) {
    throw 'useSysadmin must be used within a SysadminProvider';
  }

  return context;
}
