import { tailwindMerge } from '@air/tailwind-variants';
import * as ReachDialog from '@reach/dialog';
import { animated, config, useTransition } from '@react-spring/web';
import type { ComponentProps } from 'react';

export type ModalSize = 'small' | 'medium' | 'large';

const MODAL_SIZE_CLASS_NAME: { [key in ModalSize]: string } = {
  small: 'w-[400px]',
  medium: 'w-[496px]',
  large: 'w-[800px]',
};

const AnimatedOverlay = animated(ReachDialog.DialogOverlay);
const AnimatedContent = animated(ReachDialog.DialogContent);

export type ModalProps = Pick<
  ReachDialog.DialogOverlayProps,
  | 'allowPinchZoom'
  | 'dangerouslyBypassFocusLock'
  | 'dangerouslyBypassScrollLock'
  | 'initialFocusRef'
  | 'isOpen'
  | 'onDismiss'
  | 'unstable_lockFocusAcrossFrames'
> &
  ReachDialog.DialogContentProps &
  Omit<ComponentProps<typeof AnimatedContent>, 'className'> &
  Pick<ComponentProps<'div'>, 'className'> & {
    overlayClassName?: string;
    size?: ModalSize;
  };

export const Modal = ({
  allowPinchZoom,
  children,
  className,
  dangerouslyBypassFocusLock,
  dangerouslyBypassScrollLock,
  initialFocusRef,
  isOpen,
  onDismiss,
  overlayClassName,
  size = 'medium',
  unstable_lockFocusAcrossFrames,
  ...restOfProps
}: ModalProps) => {
  const transitions = useTransition(isOpen, {
    config: config.stiff,
    enter: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 50 },
    leave: { opacity: 0, y: 10 },
  });

  return transitions(
    (styles, item) =>
      item && (
        <AnimatedOverlay
          allowPinchZoom={allowPinchZoom}
          className={tailwindMerge(
            'fixed inset-0 overflow-auto bg-black/75 py-8 transition-opacity',
            isOpen ? 'opacity-100' : 'opacity-0',
            overlayClassName,
          )}
          dangerouslyBypassFocusLock={dangerouslyBypassFocusLock}
          dangerouslyBypassScrollLock={dangerouslyBypassScrollLock}
          initialFocusRef={initialFocusRef}
          onDismiss={onDismiss}
          style={{ opacity: styles.opacity }}
          unstable_lockFocusAcrossFrames={unstable_lockFocusAcrossFrames}
        >
          <AnimatedContent
            className={tailwindMerge(
              'relative mx-auto mt-[calc(10vw-32px)] min-h-[100px] max-w-[100vw] rounded-md bg-surface-1 p-6 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-9',
              MODAL_SIZE_CLASS_NAME[size],
              className,
            )}
            style={{ transform: styles.y.to((value) => `translate3d(0px, ${value}px, 0px)`) }}
            {...restOfProps}
          >
            {children}
          </AnimatedContent>
        </AnimatedOverlay>
      ),
  );
};
