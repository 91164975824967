import { Swap } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';

import { useSysadmin } from './SysadminProvider';

export const SysadminClearWorkspaceButton = () => {
  const { handleClearWorkspaceId, isInternal } = useSysadmin();

  if (!isInternal) return null;

  return (
    <IconButton
      appearance="ghost"
      className="bg-black text-white hover:bg-black hover:text-white active:bg-black active:text-white"
      color="grey"
      onClick={handleClearWorkspaceId}
      icon={Swap}
      label="Switch"
    />
  );
};
