import { Workspaces } from '@air/api';
import type { Workspace } from '@air/api/types';
import { FormikField } from '@air/formik-field';
import { InfoFilled } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { Modal, ModalTitle } from '@air/primitive-modal';
import { Tooltip } from '@air/primitive-tooltip';
import { uuidRegExpString } from '@air/regex';
import { Form, Formik, type FormikHelpers } from 'formik';
import { memo, useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';

import { useSysadmin } from './SysadminProvider';

export const SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY = 'air/sysadmin/recentlyUsed';

export const SYSADMIN_SET_WORKSPACE_MODAL_VALIDATION = object().shape({
  workspaceId: string()
    .matches(new RegExp(`.*(${uuidRegExpString}).*`, 'i'), 'Invalid workspace ID')
    .required('Workspace ID is required'),
});

export type SysadminSetWorkspaceModalValues = {
  workspaceId: string;
};

export const InternalSetWorkspaceModal = memo(() => {
  const { handleSetWorkspaceId } = useSysadmin();
  const [recentlyUsed, setRecentlyUsed] = useState<Pick<Workspace, 'id' | 'name'>[]>([]);

  const handleClearRecentlyUsed = useCallback(() => {
    localStorage.removeItem(SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY);
    setRecentlyUsed([]);
  }, []);

  const handleSetWorkspace = useCallback(
    async (
      { workspaceId }: SysadminSetWorkspaceModalValues,
      formikHelpers?: FormikHelpers<SysadminSetWorkspaceModalValues>,
    ) => {
      formikHelpers?.setSubmitting(true);

      if (workspaceId) {
        const workspace = await Workspaces.get({ workspaceId });

        if (workspace) {
          handleSetWorkspaceId(workspace.id);

          if (recentlyUsed.length) {
            const index = recentlyUsed.findIndex((workspace) => workspace.id === workspaceId);

            if (index !== -1) {
              localStorage.setItem(
                SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY,
                JSON.stringify([
                  recentlyUsed[index],
                  ...recentlyUsed.slice(0, index),
                  ...recentlyUsed.slice(index + 1),
                ]),
              );
            } else {
              localStorage.setItem(
                SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY,
                JSON.stringify([{ id: workspaceId, name: workspace.name }, ...recentlyUsed]),
              );
            }
          } else {
            localStorage.setItem(
              SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY,
              JSON.stringify([{ id: workspaceId, name: workspace.name }]),
            );
          }
        }
      }

      formikHelpers?.setSubmitting(true);
    },
    [handleSetWorkspaceId, recentlyUsed],
  );

  /**
   * Load recently used workspaces from local storage.
   */
  useEffect(() => {
    const recentlyUsed = localStorage.getItem(SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY);

    if (recentlyUsed) {
      setRecentlyUsed(JSON.parse(recentlyUsed));
    }
  }, []);

  /**
   * If the user has a workspace id in the URL, we'll set the workspace id
   * regardless if the user opens the modal or not.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const workspaceId = searchParams.get('wsid');

    if (workspaceId) {
      handleSetWorkspace({ workspaceId });
    }
  }, [handleSetWorkspace]);

  return (
    <Modal isOpen>
      <header className="-mx-6 -mt-6 mb-6 rounded-t-md bg-pigeon-900 p-6 text-white">
        <ModalTitle className="text-white">Workspace ID</ModalTitle>
      </header>
      <div className="flex flex-col gap-6">
        <Formik<SysadminSetWorkspaceModalValues>
          initialValues={{ workspaceId: '' }}
          onSubmit={handleSetWorkspace}
          validationSchema={SYSADMIN_SET_WORKSPACE_MODAL_VALIDATION}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-6">
              <FormikField
                component={<Input required type="text" size="large" />}
                id="workspaceId"
                label="Workspace ID"
                name="workspaceId"
              />
              <Button className="bg-pigeon-900 hover:bg-pigeon-800" isLoading={isSubmitting} size="large" type="submit">
                Go to workspace
              </Button>
            </Form>
          )}
        </Formik>

        {!!recentlyUsed.length && (
          <>
            <div className="h-px w-full bg-pigeon-100" />

            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between gap-2">
                <span className="text-14 font-semibold">Recently used</span>
                <button
                  className="border-0 bg-none text-12 font-semibold hover:underline"
                  onClick={handleClearRecentlyUsed}
                >
                  Clear all
                </button>
              </div>
              <div className="flex flex-wrap gap-2">
                {recentlyUsed.map((workspace) => (
                  <button
                    className="flex h-8 cursor-pointer items-center gap-2 rounded border border-pigeon-100 bg-pigeon-50 px-3 text-12 font-semibold"
                    key={workspace.id}
                    onClick={() => handleSetWorkspace({ workspaceId: workspace.id })}
                  >
                    {workspace.name}

                    <Tooltip label={workspace.id}>
                      <InfoFilled className="h-4 w-4" />
                    </Tooltip>
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
});

InternalSetWorkspaceModal.displayName = 'InternalSetWorkspaceModal';
