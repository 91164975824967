import { Close as CloseIcon } from '@air/next-icons';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import classNames from 'classnames';
import { ComponentProps } from 'react';

const modalCloseButton = tailwindVariants({
  variants: {
    size: {
      'extra-small': 'h-4 w-4',
      small: 'h-6 w-6',
    },
  },
  defaultVariants: {
    size: 'extra-small',
  },
});

export type ModalCloseButtonVariants = VariantProps<typeof modalCloseButton>;

export type ModalCloseButtonProps = ComponentProps<'button'> & ModalCloseButtonVariants;

export const ModalCloseButton = ({ className, size, ...restOfProps }: ModalCloseButtonProps) => {
  return (
    <button
      className={classNames(
        'flex h-6 w-6 items-center justify-center rounded border-0 bg-transparent text-grey-11 outline-none hover:bg-grey-4 focus-visible:ring-2 focus-visible:ring-blue-9 active:bg-grey-4',
        className,
      )}
      {...restOfProps}
    >
      <VisuallyHidden>Close modal</VisuallyHidden>
      <CloseIcon className={modalCloseButton({ size })} />
    </button>
  );
};
